import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { ILeaseInfo, IPatchLeaseRequest } from '@register'

export const useApiPatchLeaseMutation = (
  divisionId: string,
  leaseId: string,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body: IPatchLeaseRequest) =>
      fetchAPI<ILeaseInfo>(`/companies/${divisionId}/leases/${leaseId}`, {
        method: 'PATCH',
        body,
      }),
    onSuccess: (data, body) => {
      queryClient.setQueryData(
        ['companies', divisionId, 'leases', leaseId],
        data,
      )
      queryClient.invalidateQueries({
        queryKey: ['companies', divisionId, 'leases', leaseId, 'summary'],
      })
    },
  })
}
