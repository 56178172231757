<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal'
import { toast } from 'vue3-toastify'

interface Props {
  name: string
}

const props = defineProps<Props>()
const unrefName = unref(props.name).replace(/\s+/g, ' ')
const newName = ref(unrefName)

const emit = defineEmits(['close'])
const companyId = getCurrentDivisionId()
const leaseId = getCurrentLeaseId()

const { mutateAsync: saveLease, isPending } = useApiPatchLeaseMutation(
  companyId,
  leaseId,
)

const changeName = useSubmitHandler(
  () =>
    saveLease({
      name: newName.value,
    }),
  {
    loadingMessage: 'Changing name...',
    successMessage: 'Lease name changed',
    errorMessage: 'Error occurs while changing the name',
    beforeSubmit: () => {
      if (!newName.value || newName.value.length < 3) return false
    },
    onSuccess: () => {
      emit('close')
    },
  },
)
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="{
      enterActiveClass: 'transition duration-300 ease-in-out transform',
      enterFromClass: '-translate-y-6 opacity-0',
      enterToClass: 'translate-y-0 opacity-100',
      leaveActiveClass: 'transition duration-300 ease-in-out transform',
      leaveToClass: 'translate-y-6 opacity-0',
      leaveFromClass: '-translate-y-0 opacity-100',
    }"
  >
    <ModalContainer
      title="Change Lease Name"
      close-on-click
      @close="$emit('close')"
    >
      <div>
        <FormKit
          v-model="newName"
          type="text"
          label="New name"
          validation="required|length:3,120"
          validation-visibility="live"
          :validation-messages="{
            length:
              'Lease name must contains at least 3 characters and no longer than 120.',
          }"
        />
      </div>

      <div class="mt-6 flex justify-end gap-2">
        <Button
          color="transparentSecondary"
          :disabled="isPending"
          @click.prevent="$emit('close')"
        >
          Cancel
        </Button>

        <Button
          :loading="isPending"
          type="submit"
          class=""
          @click="changeName()"
        >
          Change
        </Button>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>

<style lang="postcss" scoped>
.lease-name {
  word-break: break-word;
}
</style>
